import { GetStaticProps } from "next";
import Index, { HomepageProps } from "../components/pages/Index";
import { fetchEncode, getLanguage } from "../utils/Helper";


const Homepage = (props: HomepageProps) => {
    return (
        <Index {...props} langCode="id" />
    )
}

export const getStaticProps: GetStaticProps = async () => {
    const lang = getLanguage('id')

    const [startListingRes, optionsRes, apartmentPopularRes] = await Promise.all([
        fetchEncode(`${process.env.NEXT_PUBLIC_PHP_API_URL}/site/starlisting-homepage?area_slug=jakarta-selatan`), 
        fetchEncode(`${process.env.NEXT_PUBLIC_PHP_API_URL}/site/options/list?listing_type_id=1`),
        fetchEncode(`${process.env.NEXT_PUBLIC_PHP_API_URL}/site/popular-apartment-homepage?area_slug=jakarta-selatan`)
    ])

    if (!startListingRes.ok || !optionsRes.ok || !apartmentPopularRes.ok) {
        return {
            props: {
                homepageResponse: {
                    star_listing: [],
                    apartment_popular: []
                },
                optionsResponse: [],
                fallback: true
            },
            revalidate: 30,
        }
    }

    let homepageResponse: any = {}
    homepageResponse.star_listing = await startListingRes.json()
    homepageResponse.apartment_popular = await apartmentPopularRes.json()
    
    const optionsResponse = await optionsRes.json()
    
    const apartmentData = optionsResponse.filter((data: any) => data.type === 'apartment').map((data: any) => {
        return {
            ltext: data.name,
            lt: 'apat',
            lid: data.id,
            slug: `${data.province.toLowerCase().replace(/\s/g, '-')}/${data.area.toLowerCase().replace(/\s/g, '-')}/${data.district.toLowerCase().replace(/\s/g, '-')}/${data.slug}` || null,
            district: data.district || null,
            area: data.area || null,
            url: data.url
        }
    })
    const districtData = optionsResponse.filter((data: any) => data.type === 'district').map((data: any) => {
        return {
            ltext: data.name,
            lt: data.type,
            lid: data.id,
            slug: `${data.province.toLowerCase().replace(/\s/g, '-')}/${data.area_slug}/${data.slug}` || null,
            district: data.district || null,
            area: data.area || null,
            url: data.url
        }
    })
    const areaData = optionsResponse.filter((data: any) => data.type === 'area').map((data: any) => {
        return {
            ltext: data.name,
            lt: data.type,
            lid: data.id,
            slug: `${data.province.toLowerCase().replace(/\s/g, '-')}/${data.slug}` || null,
            district: data.district || null,
            area: null,
            url: data.url
        }
    })
    const provinceData = optionsResponse.filter((data: any) => data.type === 'province').map((data: any) => {
        return {
            ltext: data.name,
            lt: data.type,
            lid: data.id,
            slug: `${data.name.toLowerCase().replace(/\s/g, '-')}` || null,
            district: data.district || null,
            area: data.area || null,
            url: data.url
        }
    })
    const output = [
        {
            title: lang.apartment,
            locations: apartmentData
        },
        {
            title: 'Area',
            locations: areaData
        },
        {
            title: 'District',
            locations: districtData
        },
        {
            title: lang.province,
            locations: provinceData
        }
    ].filter(loc => loc.locations.length > 0)

    return {
        props: {
            homepageResponse,
            optionsResponse: output,
            fallback: true
        },
        revalidate: 30,
    }
}

export default Homepage